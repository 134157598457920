<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <ScrimNotes v-bind="data" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'

import { getScrimNotes, getNoteTags } from '@/api/notes'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import ScrimNotes from '@/components/scrim/ScrimNotes.vue'

export default {
  name: 'ScrimNotesPage',
  components: {
    ScrimNotes,
    ApiLoadingController,
  },
  props: {
    scrimId: px.string.isRequired,
  },
  computed: {
    params() {
      return this.scrimId
    },
  },
  methods: {
    async fetch(scrimId, options) {
      const notes = await getScrimNotes(scrimId, options)
      const noteTags = await getNoteTags(options)
      notes?.forEach(note => {
        note.tags = note.tags.map(tagId => noteTags.find(tag => tagId === tag.id))
      })

      return {
        notes,
        noteTags,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
